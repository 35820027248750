import config from '../../config'
import store from '../store'

export default {
  namespaced: true,
  state: {
    sucursales: [],
    dias_semana: []
  },
  mutations: {
    set_sucursales (state, payload) {
      state.sucursales = payload
    },
    set_dias_semana (state, payload) {
      state.dias_semana = payload
      localStorage.setItem('dias_semana', JSON.stringify(payload))
    }
  },
  actions: {
    async modificar_serie ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/acc/series/modificar`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async permniso_modif_serie () {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/acc/series/permisoModif`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()

          resolve(data.permiso == 1 ? true : false)
    
        } catch (error) {
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
          resolve(false)
        }
      })
    },
    async historico_series ({}, serie) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/acc/series/historico`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ serie: serie })
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_dias ({ state, commit }) {
      try {
        if (state.dias_semana.length == 0) {
          const res = await fetch(`${config.BASE_URL}/acc/diasSemana`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_dias_semana', data.data)
          } else {
            commit('set_dias_semana', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_dias_semana', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_vendedores ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/acc/getVendedores`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(filtro)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_sucursales ({ commit, state }) {
      if (state.sucursales.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/acc/sucursales`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_sucursales', data.data)
          } else {
            commit('set_sucursales', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
  
        } catch (error) {
          commit('set_sucursales', [])
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      }
    },
    async get_localidades ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/acc/localidades?provincia=${payload}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_localidades_x_provincia({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let localidadesPeticion = await fetch(`${config.BASE_URL}/adminClientes/getLocalidadesXProvincia?provincia_codigo=${datos.provincia_codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let localidades = await localidadesPeticion.json()
          localidades.msj = localidades.message
          return resolve(localidades)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo get_localidades_x_provincia: ' + error.message
          })
        }
      })
    },
    async get_provincias () {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/acc/provincias`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_condiciones_iva () {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/acc/condicionesIVA`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async getShoppingFile({}, datos){
      return await new Promise(async (resolve, reject) => {
        try {
          let filePeticion = await fetch(`${config.BASE_URL}/acc/getShoppingFile?fechaDesde=${datos.fechaDesde}&fechaHasta=${datos.fechaHasta}&sucursal=${datos.sucursal}&local=${datos.local}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let file = await filePeticion.json()
          file.msj = file.message
          return resolve(file)
        }catch (error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getShoppingFile: ' + error.message
          })
        }
      })
    },
    async get_comprobante_id({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let idPeticion = await fetch(`${config.BASE_URL}/acc/get_comprobante_id?numero=${datos.numero}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let idComp = await idPeticion.json()
          idComp.msj = idComp.message
          return resolve(idComp)
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo get_comprobante_id: ' + error.message
          })
        }
      })
    },
  }
}